import { Logger } from '../../Logger';
import { checkLegacyTableExists } from './checkLegacyTableExists';
import { deleteLegacyTable } from './deleteLegacyTable';
import { getMigrateableDataCount } from './getMigrateableDataCount';
import { migrateDataFromLegacyTable } from './migrateDataFromLegacyTable';

export const migrateLegagyData = async (db: SQLitePlugin.Database): Promise<void> => {
    try {
        Logger.info('Upgrading database from data duplicating version');

        const legacyTableExists = await checkLegacyTableExists(db);
        if (!legacyTableExists) return;

        const migrateableDataCount = await getMigrateableDataCount(db);

        if (migrateableDataCount > 0) await migrateDataFromLegacyTable(db);

        await await deleteLegacyTable(db);
    } catch (error) {
        Logger.error('Error during upgradeDatabaseFromDataDuplicatingVersion:', error);
    }
};
